import React from 'react'
import styled from 'styled-components'

import Layout from '../components/layout'
import PDFViewer from '../components/pdf-viewer'
import styles from '../styles'

const TopElement = styled.div`
  margin: -2em -2em 2em -2em;
  padding: 2em;
  background: ${styles.lavenderLight};
`

const PDF_LINK = "https://drive.google.com/file/d/1x4rxzM1QZ42lFKDVOhu0nTSAuCpA1WyM/preview"

const ResumePage = () => (
  <Layout>
    <TopElement>Resume</TopElement>
    <PDFViewer pdfLink={ PDF_LINK } />
  </Layout>
)

export default ResumePage
