import React from 'react'
import styled from 'styled-components'

const ViewerIframe = styled.iframe`
  height: 90%;
  width: 70%;
`

const PDFViewer = ({ pdfLink }) => <ViewerIframe src={pdfLink} />

export default PDFViewer
